<template>
  <div>
    <div class="title">员工管理</div>
    <div v-if="showUserList">
      <div class="table">
        <el-table :data="tableData" :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'bold',
            'font-size': '12px',
            color: '#333'
          }" :cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333'
          }" style="width: 100%" size="mini">
          <el-table-column v-for="(item, index) in tableHeader" :key="index" :prop="item.prop" :label="item.label" :width="item.width">
            <template slot-scope="scope">
              <span v-if="item.prop !== 'operate'">{{
                scope.row[item.prop]
              }}</span>
              <div v-else>
                <el-button v-for="(item, index) in scope.row.operate" :key="index" type="text" size="mini" @click="edit(scope.row)">{{ item.name }}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="btn">
        <div class="add-btn" @click="addUser">添加用户</div>
      </div>
    </div>

    <div class="form" v-if="showAddUser">
      <el-form ref="form" :model="form" :rules="rules" label-width="180px" size="small">
        <el-form-item label="登录名：" prop="username">
          <el-input :disabled="isEdit" v-model.trim="form.username" style="width: 260px;" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="pwd" v-if="!isEdit">
          <el-input maxlength="11" type="password" v-model.trim="form.pwd" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码：" prop="npwd" v-if="!isEdit">
          <el-input maxlength="11" type="password" v-model.trim="form.npwd" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="name">
          <el-input maxlength="50" v-model.trim="form.name" @input="checkName" style="width: 260px;"></el-input>
          <span>（请输入真实姓名）</span>
        </el-form-item>
        <!-- <el-form-item label="电话：">
          <el-input
            v-model.trim="form.phoneNumber"
            style="width: 260px;"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="手机：" prop="mobileNumber">
          <el-input v-model.trim="form.mobileNumber" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model.trim="form.role">
            <el-radio v-for="(item, index) in radioList" :key="index" :label="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <div class="submit-btn" @click="onSubmit('form')">保存</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      showUserList: true,
      showAddUser: false,
      form: {
        username: '',
        pwd: '',
        npwd: '',
        name: '',
        mobileNumber: '',
        role: ''
      },
      rules: {
        username: [
          { required: true, message: "请输入登录名", trigger: "blur" }
        ],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        npwd: [{ required: true, message: "请再次输入密码", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobileNumber: [
          {
            required: true, 
            pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ]
      },

      isEdit: false,

      tableHeader: [],
      radioList: []
    };
  },
  created() {
    this.getUserList();
  },
  computed: {
    userInfo() {
      return this.$store.state.UserInfo;
    }
  },
  methods: {
    getUserList() {
      let params = {
        process_id: "00000202", //流程ID
        nodeInfo: {
          //传参  查询则是查询条件  可传可不传等
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0 //分页页码（从0开始）
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then(res => {
          const { data } = res;
          this.tableHeader = data.title;
          this.tableHeader.forEach(item => {
            item.width = 260;
          });
          this.tableData = data.content;
        })
        .catch(err => { });
    },
    addUser() {
      (this.form.username = "") /*登录名*/,
        (this.form.pwd = "") /*密码*/,
        (this.form.name = "") /*姓名*/,
        (this.form.mobileNumber = "") /*手机*/,
        (this.form.role = ""); /*角色*/
      this.showUserList = false;
      this.showAddUser = true;
      this.isEdit = false;
      //获取角色列表
      this.getRoleList();
    },
    getRoleList() {
      let params = {
        process_id: "00000202",
        nextProcessNode: "002",
        addr: "getOrgUserRole", // 获取列表 接口返回的url.getCurUserRoleUrl.addr 的值
        nodeInfo: {
          systemId: this.userInfo.currentSystemId, //登录账号成功的系统id
          organizationId: "-all-", //当前用户机构id
          size: "-1"
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then(result => {
          const { data } = result;
          this.radioList = data.content || [];
        })
        .catch(err => { });
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          //添加用户
          let params = {
            process_id: "00000202",
            execution_type: "set", //get 代表读请求 获取数据,  set	代表写请求 编辑等操作
            nextProcessNode: !this.isEdit ? "002" : "001", // 上面接口的  button.NodeId_1字段的值
            nodeInfo: {
              username: this.form.username /*登录名*/,
              password: this.form.pwd /*密码*/,
              name: this.form.name /*姓名*/,
              // phoneNumber: this.form.phoneNumber /*电话*/,
              mobileNumber: this.form.mobileNumber /*手机*/,
              role: this.form.role /*角色*/
            }
          };
          this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
            .then(res => {
              if (res.succeed == 0) {
                this.showUserList = true;
                this.showAddUser = false;
                //获取用户列表
                this.getUserList();
              }
            })
            .catch(err => { });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    edit(data) {
      this.showAddUser = true;
      this.showUserList = false;
      this.isEdit = true;
      this.form = data;
      //获取角色列表
      this.getRoleList();
    },
    checkName() {
      this.form.name = this.form.name.replace(/[\\\/]/g, '');
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.table {
  margin-top: 30px;
}
.btn {
  .add-btn {
    background: #2d5171;
    color: #fff;
    padding: 5px 20px;
    width: 100px;
    font-size: 12px;
    text-align: center;
    box-sizing: border-box;
    margin: 28px auto 0 auto;
    cursor: pointer;
  }
}
.form {
  margin: 10px 0;
  padding: 10px;
  background: #fff;
  .submit-btn {
    background-color: #2d5171;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    width: 66px;
    padding: 5px 20px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
  }
}
</style>
